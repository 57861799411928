import React, { FC, useContext, useState } from 'react'
import { Box, Button } from '@mui/material'
import { styled } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import ListUser from './ListUser'
import { useDispatch, useSelector } from 'react-redux'
import { getParticipants, setParticipants, nullParticipants } from '../../../../redux/reducers/drills_reducer'
import { AppStateType } from '../../../../redux/store'
import { LanguageContext, translate } from '../../../../constants/lang'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`

const style = {
  box: {
    width: 792,
    maxHeight: 751,
    bgcolor: 'white',
    border: '0px solid #000',
    borderRadius: 6,
    overflow: 'auto',
  },
  info: {
    padding: '24px 44px 12px 44px',
    overflow: 'auto',
    maxHeight: 717,
  },
  title: {
    fontWeight: 800,
    fontSize: 49,
    mb: 2,
  },
  button: {
    m: 4,
  },
  drill: {
    fontWeight: 800,
    fontSize: 20,
  },
}

export const EditParticipants: FC<any> = (props) => {
  const dispatch = useDispatch()
  const [checkedUsers, setCheckedUsers] = useState<string[]>([])
  const [checkedParticipants, setCheckedParticipants] = useState<string[]>([])
  const [checkedUsersRole, setCheckedUsersRole] = useState<any>([])
  const { language } = useContext(LanguageContext)

  React.useEffect(() => {
    if (!props.isOpenEditParticipants) return
    dispatch(getParticipants(props.drillId))
  }, [props.isOpenEditParticipants])
  let selectParticipants = useSelector((store: AppStateType) => store.Drills.participantsSelectTeam)
  React.useEffect(() => {
    if (!props.isOpenEditParticipants) return
    let arrParticipants = selectParticipants.map((item: any) => {
      item[1].roleId = item[2].id
      item[1].isChecked = true
      item[3].length > 0 ? (item[1].isLeader = true) : (item[1].isLeader = false)
      return item[1]
    })
    let arrParticipantsRole = selectParticipants.map((item: any) => {
      return {
        drillId: props.drillId,
        login: item[1].login,
        roleId: item[1].roleId,
        otherRole: item[1].isLeader,
      }
    })
    let arrUsers = selectParticipants.map((item: any) => item[1].login)
    setCheckedParticipants(arrParticipants)
    setCheckedUsers(arrUsers)
    setCheckedUsersRole(arrParticipantsRole)
  }, [selectParticipants, props.isOpenEditParticipants])

  const setNewParticipants = () => {
    dispatch(setParticipants(checkedUsersRole))
    dispatch(nullParticipants(props.drillId))
    props.closeEditParticipants()
  }

  return (
    <>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={props.isOpenEditParticipants}
        onClose={props.closeEditParticipants}
        BackdropComponent={Backdrop}
      >
        <Box sx={style.box}>
          <Box sx={style.info}>
            <Box sx={style.title}>{translate('editParticipants', language)}</Box>
            <ListUser
              checkedUsers={checkedUsers}
              setCheckedUsers={setCheckedUsers}
              checkedParticipants={checkedParticipants}
              checkedUsersRole={checkedUsersRole}
              setCheckedUsersRole={setCheckedUsersRole}
              drillId={props.drillId}
            />
          </Box>
          <Box sx={style.button}>
            <Button onClick={setNewParticipants} variant="contained" size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('labelSave', language)}
            </Button>
            <Button onClick={props.closeEditParticipants} size="large" sx={{ mr: 2, borderRadius: '14px' }}>
              {translate('labelCancel', language)}
            </Button>
          </Box>
        </Box>
      </StyledModal>
    </>
  )
}
