import { createContext, useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'

type LanguageContextType = {
  language: string
  changeLanguage: (lang: string) => void
}

export const LanguageContext = createContext<LanguageContextType>({
  language: 'ru',
  changeLanguage: () => {},
})

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = useState<string>('ru')

  const changeLanguage = (lang: string) => {
    setLanguage(lang)
    sessionStorage.setItem('lang', lang)
  }

  return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>
}

export type TranslationType = {
  [key: string]: string
}

export const translate = (key: string, language: string): string => {
  switch (language) {
    case 'en':
      return EN_TRANSLATIONS[key]
    case 'ru':
      return RU_TRANSLATIONS[key]
    default:
      return RU_TRANSLATIONS[key]
  }
}

export function ucFirst(str: string): string {
  if (!str) return str

  return str[0].toUpperCase() + str.slice(1)
}

export const RU_TRANSLATIONS: TranslationType = {
  '@@locale': 'ru',
  pressService: 'Пресс-служба',
  socialNetworks: 'Социальные сети',
  massMedia: 'СМИ',
  pressServicePub: 'ПУБЛИКАЦИЯ ПРЕСС-СЛУЖБЫ',
  socialNetworkDiscussion: 'ОБСУЖДЕНИЕ В СОЦИАЛЬНЫХ СЕТЯХ',
  massMediaPub: 'ПУБЛИКАЦИЯ СМИ',
  changePassword: 'Изменить пароль',
  labelDone: 'Готово',
  passwordChanged: 'Пароль успешно изменен',
  enterOldPassword: 'Введите старый пароль',
  enterNewPassword: 'Введите новый пароль',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  repeatNewPassword: 'Повторите новый пароль',
  newPasswordsNotMatch: 'Поля нового пароля не совпадают',
  commonChat: 'Общий чат',
  labelAll: 'Все',
  labelRole: 'Роль',
  labelDrill: 'Тренировка',
  leaveDrill: 'Покинуть тренировку',
  description: 'Описание',
  labelContinue: 'Продолжить',
  activePl: 'Активные',
  passedPl: 'Пройденные',
  inPeriod: 'ЧЕРЕЗ {daysNo} {dayWordForm, select, 1{день} 2{дня} other{дней}}',
  now: 'СЕЙЧАС',
  noActiveDrills: 'Нет активных тренировок',
  noPassedDrills: 'Нет пройденных тренировок',
  addServer: 'Добавить сервер',
  editServer: 'Редактировать сервер',
  labelSave: 'Сохранить',
  enterTitle: 'Введите название',
  labelTitle: 'Название',
  mandatoryField: 'Обязательное поле',
  incorrectUrl: 'Некорректный Url',
  labelAddress: 'Адрес',
  labelHelp: 'Помощь',
  faq: 'Часто задаваемые вопросы',
  instructions: 'Инструкции',
  fileSaved: 'Файл {fileName} сохранен в загрузки',
  drills: 'Тренировки',
  profile: 'Профиль',
  serverNotSelected: 'Сервер не выбран',
  doLogin: 'Выполните вход',
  forgotPassword: 'Забыли пароль?',
  labelEnter: 'Войти',
  selectServer: 'Выберите сервер',
  labelServer: 'Сервер',
  enterLogin: 'Введите логин',
  labelLogin: 'Логин',
  enterPassword: 'Введите пароль',
  labelPassword: 'Пароль',
  labelEmail: 'E-mail',
  labelAlias: 'Псевдоним',
  labelLanguage: 'Язык',
  organization: 'Организация',
  organizationTitle: 'Название организации',
  labelPosition: 'Должность',
  positionTitle: 'Название должности',
  exitProfile: 'Выйти из профиля?',
  enterEmail: 'Введите e-mail',
  incorrectEmail: 'Некорректный e-mail адрес',
  restoreAccess: 'Восстановить доступ',
  restoreAccessInstructionSent: 'Инструкция по восстановлению доступа в течение 5 минут придет вам на почту',
  listIsEmpty: 'Список пуст',
  labelEdit: 'Редактировать',
  labelDelete: 'Удалить',
  rea: 'АО "Концерн Росэнергоатом"',
  authFailure: 'Ошибка авторизации',
  labelReply: 'Ответить',
  leader: 'Ведущий',
  labelDownload: 'Скачать',
  labelDeleted: 'Удалено',
  labelEdited: 'Изменено',
  labelRejected: 'Отказано',
  labelConfirmed: 'Согласовано',
  labelIdle: 'Ожидает',
  failedToDisplayMessage: 'Не удалось отобразить сообщение',
  message: 'Сообщение',
  labelCancel: 'Отмена',
  labelExit: 'Выйти',
  enterSearchWord: 'Введите слово для поиска',
  searchDrill: 'Поиск тренировки',
  labelFilters: 'Фильтры',
  dateStart: 'Дата начала',
  dateEnd: 'Дата окончания',
  dateFrom: 'С',
  dateTo: 'По',
  searchAmong: 'Искать среди',
  invalidDatePeriod: 'Введите корректный период дат',
  labelRoles: 'Ролей',
  participants: 'Участников',
  selectAll: 'Выбрать всех',
  apply: 'Применить',
  close: 'Закрыть',
  reset: 'Сбросить',
  journal: 'Журнал',
  editParticipants: 'Редактировать участников',
  displayingChats: 'Отображение чатов',
  showSystemMessages: 'Показывать системные сообщения',
  joinToChat: 'Присоединился(лась)',
  leftTheChat: 'Покинул(а)',
  settings: 'Настройки',
  showChats: 'Показывать чаты',
  menu: 'Меню',
  export: 'Экспорт',
  editDrill: 'Редактировать тренировку',
  withAgreement: 'С согласованием',
  messageFor: 'Сообщение для',
  send: 'Отправить',
  header: 'Заголовок',
  deleteDrill: 'Удалить тренировку?',
  messageDeleteDrill: 'Все данные будут стёрты. Восстановить их будет невозможно!',
  fullname: 'ФИО',
  newPhoto: 'Новое фото',
  users: 'Пользователи',
  accessRights: 'Права доступа',
  roles: 'Роли',
  groups: 'Группы',
  help: 'Помощь',
  deleteUserTittle: 'Удалить пользователя?',
  deleteMessage: 'Все данные будут стёрты. Восстановить их будет невозможно!',
  selectDefaultRole: 'Выберите роль по умолчанию',
  selectDefaultAccessRight: 'Выберите доступ по умолчанию',
  addUser: 'Добавить пользователя',
  editAccessRight: 'Редактировать права доступа',
  add: 'Добавить',
  accessType: 'Тип доступа',
  accessObject: 'Объект доступа',
  permission_chat: 'Чаты',
  permission_drill: 'Тренировки',
  permission_team: 'Команды',
  permission_user: 'Пользователи',
  permission_social_role: 'Роли пользователей',
  permission_role: 'Права доступа',
  AllowWrite: 'Разрешено редактировать',
  AllowRead: 'Разрешено читать',
  RejectWrite: 'Запрещено редактировать',
  RejectRead: 'Запрещено читать',
  deleteAccessRightTittle: 'Удалить права доступа?',
  addAccessRight: 'Добавить права доступа',
  editRole: 'Редактировать роль',
  addRole: 'Добавить роль',
  type: 'Тип',
  nameRole: 'Название роли',
  roleNameMessage: 'Требуется наименование роли',
  labelCreate: 'Создать',
  roleDrills: 'Роли тренировок',
  photo: 'Фото',
  roleType: 'Тип роли',
  deleteRoleTittle: 'Удалить роль(и)?',
  numberOfParticipants: 'Кол-во участников',
  editGroup: 'Редактировать группу',
  edditor: 'Редактор',
  deleteGroupTittle: 'Удалить группу(ы)?',
  exit: 'Выход',
  exitMessage: 'Вы действительно хотите выйти?',
  descriptionProject: 'Интерактивный симулятор информационного поля',
  login: 'Войти',
  createDrill: 'Создать тренировку',
  workoutNameRequired: 'Требуется наименование тренировки',
  formTeam: 'Сформировать команду',
  team: 'Команда',
  DontFormTeam: 'Не формировать команду',
  FormFromGroup: 'Сформировать из группы',
  FormFromAnotherWorkout: 'Сформировать из другой тренировки',
  drillNoFound: 'Тренировки не найдены',
  groupslNoFound: 'Группы не найдены',
  delete: 'Удалить',
  messagesNotFound: 'Cообщения не найдены',
  publish: 'Опубликовать',
  reject: 'Отклонть',
  createGroup: 'Создать группу',
  doNotHaveRightsAccessMessages: 'Недостаточно прав для доступа к этой странице',
  findingTopicsOrQuestions: 'Поиск новых тем или вопросов',
  noQuestions: 'Пока еще нет вопросов',
  participant: 'Участник',
  question: 'вопрос',
  topic: 'topic',
  offer: 'предложение',
  askQuestion: 'Задать вопрос',
  createFirstDrill: 'Создайте первую тренировку',
  attachOneFile: 'Можно прикрепить только один файл',
  editUser: 'Редактировать пользователя',
  securitySettings: 'Параметры безопасности',
  securityEvents: 'События бозопасности',
}

export const EN_TRANSLATIONS: TranslationType = {
  '@@locale': 'en',
  pressService: 'Press services',
  pressServicePub: 'PRESS SERVICE PUBLICATION',
  socialNetworks: 'Social networks',
  socialNetworkDiscussion: 'DISCUSSION ON SOCIAL NETWORKS',
  massMedia: 'Press',
  massMediaPub: 'MEDIA PUBLICATION',
  changePassword: 'Change password',
  labelDone: 'Ready',
  passwordChanged: 'Your password has been changed successfully',
  enterOldPassword: 'Enter old password',
  enterNewPassword: 'Enter a new password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  repeatNewPassword: 'New password again',
  newPasswordsNotMatch: "New password fields don't match",
  commonChat: 'General chat',
  labelAll: 'All',
  labelRole: 'Role',
  labelDrill: 'Drill',
  leaveDrill: 'Leave drill',
  description: 'Description',
  labelContinue: 'Continue',
  activePl: 'Active',
  passedPl: 'Passed',
  inPeriod: 'AFTER {daysNo} {dayWordForm, select, 1{day} 2{days} other{days}}',
  now: 'NOW',
  noActiveDrills: 'No active drills',
  noPassedDrills: 'No passed drills',
  addServer: 'Add server',
  editServer: 'Edit server',
  labelSave: 'Save',
  enterTitle: 'Enter the title',
  labelTitle: 'Title',
  mandatoryField: 'Required field',
  incorrectUrl: 'Incorrect Url',
  labelAddress: 'Address',
  labelHelp: 'Help',
  faq: 'FAQ',
  instructions: 'Instruction',
  fileSaved: 'File {fileName} saved in download',
  drills: 'Drills',
  profile: 'My Profile',
  serverNotSelected: 'Server not selected',
  doLogin: 'Sign in',
  forgotPassword: 'Forgot your password?',
  labelEnter: 'Enter',
  selectServer: 'Select server',
  labelServer: 'Server',
  enterLogin: 'Enter login',
  labelLogin: 'Login',
  enterPassword: 'Enter password',
  labelPassword: 'Password',
  labelEmail: 'E-mail',
  labelAlias: 'Nickname',
  labelLanguage: 'Language',
  organization: 'Organization',
  organizationTitle: 'Name of the organization',
  labelPosition: 'Position',
  positionTitle: 'Position title',
  exitProfile: 'Log out',
  enterEmail: 'Enter e-mail',
  incorrectEmail: 'Incorrect e-mail',
  restoreAccess: 'Restore access',
  restoreAccessInstructionSent: 'Instructions for restoring access will be sent to your e-mail within 5 minutes',
  listIsEmpty: 'The list is empty',
  labelEdit: 'Edit',
  labelDelete: 'Delete',
  rea: 'JSC "Rosenergoatom Concern"',
  authFailure: 'Authorisation Error',
  labelReply: 'Reply',
  leader: 'Leader',
  labelDownload: 'Download',
  labelDeleted: 'Deleted',
  labelEdited: 'Edited',
  labelRejected: 'Denied',
  labelConfirmed: 'Agreed',
  labelIdle: 'Pending',
  failedToDisplayMessage: 'Failed to display message',
  message: 'Message',
  labelCancel: 'Cancel',
  labelExit: 'Exit',
  enterSearchWord: 'Enter a search term',
  searchDrill: 'Search of a drill',
  labelFilters: 'Filters',
  dateStart: 'Date start',
  dateEnd: 'Date end',
  dateFrom: 'From',
  dateTo: 'To',
  searchAmong: 'Search among',
  invalidDatePeriod: 'Please enter a valid date period',
  labelRoles: 'Roles',
  participants: 'Participants',
  selectAll: 'Select all',
  apply: 'Apply',
  close: 'Close',
  reset: 'Reset',
  journal: 'Journal',
  editParticipants: 'Edit participants',
  displayingChats: 'Displaying chats',
  showSystemMessages: 'Show system messages',
  joinToChat: 'Join to chat',
  leftTheChat: 'Left the chat',
  settings: 'Settings',
  showChats: 'Show chats',
  menu: 'Menu',
  export: 'Export',
  editDrill: 'Edit drill',
  withAgreement: 'with agreement',
  messageFor: 'Message for',
  send: 'Send',
  header: 'Header',
  deleteDrill: 'Delete a Drill?',
  messageDeleteDrill: 'All data will be erased. It will be impossible to restore them!',
  fullname: 'Fullname',
  newPhoto: 'New photo',
  users: 'Users',
  accessRights: 'Access rights',
  roles: 'Roles',
  groups: 'Groups',
  help: 'Help',
  deleteUserTittle: 'Are you sure you want to delete this user?',
  deleteMessage: 'All data will be erased. It will be impossible to recover them!',
  selectDefaultRole: 'Select the default role',
  selectDefaultAccessRight: 'Select the default access right',
  addUser: 'Add user',
  editAccessRight: 'Edit access right',
  add: 'Add',
  accessType: 'Access type',
  accessObject: 'Access object',
  permission_chat: 'Chats',
  permission_drill: 'Drills',
  permission_team: 'Groups',
  permission_user: 'Users',
  permission_social_role: 'User roles',
  permission_role: 'Rights',
  AllowWrite: 'Allow write',
  AllowRead: 'Allow read',
  RejectWrite: 'Reject write',
  RejectRead: 'Reject read',
  deleteAccessRightTittle: 'Are you sure you want to delete this access right?',
  addAccessRight: 'Add access right',
  editRole: 'Edit role',
  addRole: 'Add role',
  type: 'Type',
  nameRole: 'Role name',
  roleNameMessage: 'Role name required',
  labelCreate: 'Create',
  roleDrills: 'Role drills',
  photo: 'Photo',
  roleType: 'Role type',
  deleteRoleTittle: 'Delete role?',
  numberOfParticipants: 'Number of participants',
  editGroup: 'Edit group',
  edditor: 'Edditor',
  deleteGroupTittle: 'Delete group?',
  exit: 'Exit',
  exitMessage: 'Are you sure you want to exit?',
  descriptionProject: 'Interactive information field simulator',
  login: 'Login',
  createDrill: 'Create drill',
  workoutNameRequired: 'Workout name required!',
  formTeam: 'Form a team',
  team: 'Team',
  DontFormTeam: 'Do not form a team',
  FormFromGroup: 'Form from group',
  FormFromAnotherWorkout: 'Form from another workout',
  drillNoFound: 'Drills not found',
  groupslNoFound: 'Группы не найдены',
  delete: 'Delete',
  messagesNotFound: 'Messages not Found',
  publish: 'Publish',
  reject: 'Reject',
  createGroup: 'Create group',
  doNotHaveRightsAccessMessages: 'You do not have enough rights to access this page',
  findingTopicsOrQuestions: 'Searching for new topics or questions',
  noQuestions: 'No questions yet',
  participant: 'Participant',
  question: 'question',
  topic: 'topic',
  offer: 'offer',
  askQuestion: 'Ask a Question',
  createFirstDrill: 'Create first drill',
  attachOneFile: 'You can attach only one file',
  editUser: 'Edit user',
  securitySettings: 'Security settings',
  securityEvents: 'Security Events',
}
